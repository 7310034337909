import styled from '@emotion/styled'
import { ItemMobile } from 'app/components/RoomsList/ItemMobile'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const RoomsList = memo(function RoomsList({ items }: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {uniqBy(items, 'title').map((item, index) => (
          <React.Fragment key={index}>
            <Media greaterThanOrEqual="desktopSmall">
              <Item variant={index % 2 ? 'default' : 'inverted'} {...item} />
            </Media>
            <Media lessThan="desktopSmall">
              <ItemMobile {...item} />
            </Media>
          </React.Fragment>
        ))}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section``
