import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

interface ItemProps {
  title?: string
}

export interface Props {
  description?: string
  items: ItemProps[]
  title?: string
}

export const Services = memo(function Services({
  description,
  items,
  title,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <Inner>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          <FadeInUp>
            <Items>
              {items.map((item, index) => (
                <Item key={index}>{item.title}</Item>
              ))}
            </Items>
          </FadeInUp>

          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Inner>
        <LazyLoadComponent>
          <StyledImage src="/services_bg.webp" alt="Servizi" />
        </LazyLoadComponent>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 12.5rem;
  background: ${({ theme }) => theme.colors.variants.primaryDark};

  @media (max-width: 1199px) {
    margin-top: 5.625rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 8.938vw;

  @media (max-width: 1199px) {
    margin-left: 1.5625rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0;
  }
`

const Inner = styled.div`
  position: relative;
  width: 53.5%;
  max-width: 39.296rem;
  margin-right: 10.688vw;
  margin-bottom: 3.125rem;
  z-index: 2;

  @media (min-width: 1920px) {
    max-width: 33.8125rem;
  }

  @media (max-width: 1199px) {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin: 3.75rem 0 3rem;
    padding: 0 1.5625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 3.125rem;
  letter-spacing: -0.05em;
  line-height: 3.4375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const Items = styled.ul`
  margin-top: 4.0625rem;
  columns: 2;
  column-gap: 2.8125rem;

  @media (max-width: 1199px) {
    margin-top: 3rem;
  }

  @media (max-width: 768px) {
    columns: 1;
  }
`

const Item = styled.li`
  position: relative;
  padding-left: 1.25rem;
  margin-bottom: 1.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-transform: uppercase;

  &::before {
    content: url('/services_list_arrow.svg');
    position: absolute;
    left: 0;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1.8125rem;
  margin-top: 6.25rem;

  @media (max-width: 767px) {
    margin-top: 2.5rem;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  width: 46.5%;
  height: 83.33vh;
  max-height: 46.875rem;

  @media (max-width: 1199px) {
    max-height: 34.375rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-height: 23.4375rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(90deg, #1a1919 0%, rgba(26, 25, 25, 0) 100%);
    z-index: 1;

    @media (max-width: 768px) {
      width: 100%;
      height: 50%;
      background: linear-gradient(#1a1919 0%, rgba(26, 25, 25, 0) 100%);
    }
  }
`
